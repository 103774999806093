<template>
  <div class="p-grid p-col-12 p-lg-12" :style="{ position: 'relative' }">
    <Loading :active.sync="loading" :isFullPage="false"></Loading>
    <div class="p-col-12 card p-shadow-6">
      <h2>Recursos Humanos | Relatório</h2>
    </div>
    <div class="p-col-12 card p-shadow-6">
      <DataTable :value="employeeres" responsiveLayout="scroll" ref="dt">
        <template #header>
          <div style="text-align: left">
            <MultiSelect
              :value="selectedColumns"
              :options="columns"
              optionLabel="header"
              @input="onToggle"
              placeholder="Colunas Relatório"
              style="width: 20em"
            />
            <label class="p-ml-2">Estado do Funcionário</label>
            <Dropdown
              v-model="employeStatus"
              :options="[
                { value: 0, label: 'Todos' },
                { value: 1, label: 'Ativo' },
                { value: 3, label: 'Inativo' },
              ]"
              optionValue="value"
              optionLabel="label"
              class="p-column-filter p-ml-1"
            />
            <Button
              class="p-ml-3 p-mt-3"
              label="Gerar Relatório"
              @click="getEmployees()"
            />
            <Button
              class="p-ml-3 p-mt-3"
              icon="pi pi-external-link"
              label="Export"
              @click="exportCSV($event)"
            />
          </div>
        </template>
        <Column
          v-for="(col, index) of selectedColumns"
          :field="col.field"
          :header="col.header"
          :key="col.field + '_' + index"
        >
        </Column>
      </DataTable>
    </div>
  </div>
</template>
<script>
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import employeeservice from "../services/employee.service";
export default {
  name: "HumanResourcesReport",
  components: {
    Loading,
  },
  data() {
    return {
      loading: false,
      selectedColumns: [],
      columns: [],
      employeeres: [],
      employeStatus: 0,
    };
  },
  async created() {
    this.columns = [
      { field: "name", header: "Nome" },
      { field: "number", header: "Número de Colaborador" },
      { field: "email", header: "Email Profissional" },
      { field: "department", header: "Departamento" },
      { field: "area", header: "Área" },
      { field: "office", header: "Escritório" },
      { field: "admissionDate", header: "Data de Admissão" },
      { field: "endContract", header: "Data de Fim de Contrato" },
      { field: "exitDate", header: "Data de Saída" },
      { field: "birthDate", header: "Data de Nascimento" },
      { field: "namaritalStatus", header: "Estado Civil" },
      { field: "addess", header: "Morada" },
      { field: "zipCode", header: "Código Postal" },
      { field: "local", header: "Localidade" },
      { field: "telephone", header: "Telemóvel" },
      { field: "personalEmail", header: "Email Pessoal" },
      { field: "citizenCardNum", header: "Nº do Cartão do Cidadão" },
      { field: "nif", header: "Nº Identificação Fiscal (NIF)" },
      { field: "niss", header: "Nº Segurança Social (NISS)" },
      { field: "holders", header: "IRS - Nº de Titulares" },
      { field: "irsDependents", header: "IRS - Nº de dependentes" },
      { field: "iban", header: "IBAN" },
      { field: "function", header: "Função" },
      {
        field: "function_description_url",
        header: "Descrição da Função - Url",
      },
      { field: "updated", header: "Última Actualiação" },
      { field: "employeeStatus2", header: "Estado", detail: "active" },
      { field: "haveCv", header: "Tem CV", detail: "bool" },
      { field: "haveCc", header: "Tem CC", detail: "bool" },
      {
        field: "haveQc",
        header: "Tem Certificado Habilitações",
        detail: "bool",
      },
    ];
  },
  methods: {
    onToggle(value) {
      this.selectedColumns = this.columns.filter((col) => value.includes(col));
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    async getEmployees() {
      this.loading = true;
      this.employeeres = [];
      return employeeservice.getAll().then((response) => {
        this.loading = false;
        response.forEach((element) => {
          element["employeeStatus2"] =
            element.employeeStatus == 1 ? "Ativo" : "Inativo";
          element["haveCv"] = element.documents.cv.url ? "Sim" : "Não";
          element["haveCc"] = element.documents.citizenCard.url ? "Sim" : "Não";
          element["haveQc"] = element.documents.qualificationsCertificate.url
            ? "Sim"
            : "Não";
          element["haveCarDeclaration"] = !element.hasCar
            ? "NA"
            : element.documents.carDeclaration.url
            ? "Sim"
            : "Não";
          element["dateExitDate"] =
            element.employeeExitDate != null
              ? new Date(element.employeeExitDate)
              : "";
          element["dateEndContract"] =
            element.employeeEndContract != null
              ? new Date(element.employeeEndContract)
              : "";
          element["dateEmployeeStartDate"] =
            element.employeeStartDate != null
              ? new Date(element.employeeStartDate)
              : "";
          if (this.employeStatus == 0) {
            this.employeeres.push(element);
          } else if (element.employeeStatus == this.employeStatus) {
            this.employeeres.push(element);
          }
        });
        return (this.loading = false);
      });
    },
  },
};
</script>
